<template lang="pug">
  Main#about
    section
      .container
        .row.start-lg.start-xs.middle-xs
          .col-lg-6.col-md-10.col-sm-8.col-xs-10
            h1 {{ $t("about.title") }}
          .col-lg-6.col-md-10.col-sm-8.col-xs-10
            #astronauts.box-row
              img(class="astronauta-vr floating", src="../../assets/images/astronauta-vr.png")
              img(class="meteorito-2 float-rotate", src="../../assets/images/meteorito02.svg")
              img(class="meteorito-3 float-rotate", src="../../assets/images/meteorito03.svg")
              img(class="meteorito-4 float-rotate", src="../../assets/images/meteorito04.svg")
    section#management-team
      .container
        .row.center-lg.center-xs
          .col-lg-12.col-md-10.col-sm-10.col-xs-10
            h2 {{ $t("about.management_team.title") }}
        .row.center-lg.center-xs
          .col-lg-12.col-md-10.col-sm-10.col-xs-12
            .slider-container
              slick(:options="managementTeamSlider")
                div
                  .card
                    .row.center-lg
                      .col-lg-3.col-md-3.col-sm-12.col-xs-12.center-lg.start-xs
                        h4 {{ $t("about.management_team.eduardo.name") }}
                      .col-lg-9.col-md-9.col-sm-12.col-xs-12.start-lg
                        .box.start-lg.center-md.start-xs
                          .content
                            p {{ $t("about.management_team.eduardo.text_1") }}
                            p {{ $t("about.management_team.eduardo.text_2") }}
                            p {{ $t("about.management_team.eduardo.text_3") }}
                div
                  .card
                    .row.center-lg
                      .col-lg-3.col-md-3.col-sm-12.col-xs-12.center-lg.start-xs
                        h4 {{ $t("about.management_team.mauricio.name") }}
                      .col-lg-9.col-md-9.col-sm-12.col-xs-12.start-lg
                        .box.start-lg.center-md.start-xs
                          .content
                            p {{ $t("about.management_team.mauricio.text_1") }}
                            p {{ $t("about.management_team.mauricio.text_2") }}
                div
                  .card
                    .row.center-lg
                      .col-lg-3.col-md-3.col-sm-12.col-xs-12.center-lg.start-xs
                        h4 {{ $t("about.management_team.raphael.name") }}
                      .col-lg-9.col-md-9.col-sm-12.col-xs-12.start-lg
                        .box.start-lg.center-md.start-xs
                          .content
                            p {{ $t("about.management_team.raphael.text_1") }}
                            p {{ $t("about.management_team.raphael.text_2") }}
                div
                  .card
                    .row.center-lg
                      .col-lg-3.col-md-3.col-sm-12.col-xs-12.center-lg.start-xs
                        h4 {{ $t("about.management_team.bernardo.name") }}
                      .col-lg-9.col-md-9.col-sm-12.col-xs-12.start-lg
                        .box.start-lg.center-md.start-xs
                          .content
                            p {{ $t("about.management_team.bernardo.text_1") }}
                            p {{ $t("about.management_team.bernardo.text_2") }}
                div
                  .card
                    .row.center-lg
                      .col-lg-3.col-md-3.col-sm-12.col-xs-12.center-lg.start-xs
                        h4 {{ $t("about.management_team.antonio.name") }}
                      .col-lg-9.col-md-9.col-sm-12.col-xs-12.start-lg
                        .box.start-lg.center-md.start-xs
                          .content
                            p {{ $t("about.management_team.antonio.text_1") }}
                            p {{ $t("about.management_team.antonio.text_2") }}
    section#our-teams
      img(class="mountain", src="../../assets/images/Mountain.png")
      .container
        .row.center-lg.center-xs
          .col-lg-12.col-md-10.col-sm-10.col-xs-10
            h2 {{ $t("about.our_teams.title") }}
        .row.center-lg.center-xs
          .col-lg-10.col-md-10.col-sm-10.col-xs-12
            .slider-container
              slick(:options="teamSlider" @afterChange="handleAfterChange" @init="handleInit")
                div
                  .card
                    .row.middle-lg.center-md.center-xs.middle-xs
                      .col-lg-5.col-md-4.col-sm-3.col-xs-12
                        img(class="our-teams__img", src="../../assets/images/UIUX_v2.png")
                      .col-lg-5.col-md-8.col-sm-9.col-xs-12.start-lg.start-xs
                        h3 {{ $t("about.our_teams.ux_ui.title") }}
                        .box.start-lg.start-md.center-sm.start-xs
                          .content
                            p {{ $t("about.our_teams.ux_ui.text_2") }}
                            p {{ $t("about.our_teams.ux_ui.text_3") }}
                            p {{ $t("about.our_teams.ux_ui.text_1") }}
                div
                  .card
                    .row.middle-lg.center-md.center-xs.middle-xs
                      .col-lg-5.col-md-4.col-sm-3.col-xs-12
                        img(class="our-teams__img", src="../../assets/images/RoverIOT.png")
                      .col-lg-5.col-md-8.col-sm-9.col-xs-12.start-lg.start-xs
                        h3 {{ $t("about.our_teams.iot.title") }}
                        .box.start-lg.start-md.center-sm.start-xs
                          .content
                            p {{ $t("about.our_teams.iot.text") }}
                div
                  .card
                    .row.middle-lg.center-md.center-xs.middle-xs
                      .col-lg-5.col-md-4.col-sm-3.col-xs-12
                        img(class="our-teams__img", src="../../assets/images/ArtificialInteligence_v2.png")
                      .col-lg-5.col-md-8.col-sm-9.col-xs-12.start-lg.start-xs
                        h3 {{ $t("about.our_teams.ai.title") }}
                        .box.start-lg.start-md.center-sm.start-xs
                          .content
                            p {{ $t("about.our_teams.ai.text") }}
                div
                  .card
                    .row.middle-lg.center-md.center-xs.middle-xs
                      .col-lg-5.col-md-4.col-sm-3.col-xs-12
                        img(class="our-teams__img", src="../../assets/images/satelite.png")
                      .col-lg-5.col-md-8.col-sm-9.col-xs-12.start-lg.start-xs
                        h3 {{ $t("about.our_teams.engenharia.title") }}
                        .box.start-lg.start-md.center-sm.start-xs
                          .content
                            p {{ $t("about.our_teams.engenharia.text") }}
                div
                  .card
                    .row.middle-lg.center-md.center-xs.middle-xs
                      .col-lg-5.col-md-4.col-sm-3.col-xs-12
                        img(class="our-teams__img", src="../../assets/images/Prancheta7.png")
                      .col-lg-5.col-md-8.col-sm-9.col-xs-12.start-lg.start-xs
                        h3 {{ $t("about.our_teams.infra.title") }}
                        .box.start-lg.start-md.center-sm.start-xs
                          .content
                            p {{ $t("about.our_teams.infra.text") }}
                div
                  .card
                    .row.middle-lg.center-md.center-xs.middle-xs
                      .col-lg-5.col-md-4.col-sm-3.col-xs-12
                        img(class="our-teams__img", src="../../assets/images/astronauta-vr.png")
                      .col-lg-5.col-md-8.col-sm-9.col-xs-12.start-lg.start-xs
                        h3 {{ $t("about.our_teams.ar_vr.title") }}
                        .box.start-lg.start-md.center-sm.start-xs
                          .content
                            p {{ $t("about.our_teams.ar_vr.text") }}
                div
                  .card
                    .row.middle-lg.center-md.center-xs.middle-xs
                      .col-lg-5.col-md-4.col-sm-3.col-xs-12
                        img(class="our-teams__img", src="../../assets/images/Management_v2.png")
                      .col-lg-5.col-md-8.col-sm-9.col-xs-12.start-lg.start-xs
                        h3 {{ $t("about.our_teams.gestao.title") }}
                        .box.start-lg.start-md.center-sm.start-xs
                          .content
                            p {{ $t("about.our_teams.gestao.text") }}
                div
                  .card
                    .row.middle-lg.center-md.center-xs.middle-xs
                      .col-lg-5.col-md-4.col-sm-3.col-xs-12
                        img(class="our-teams__img", src="../../assets/images/EngLaptop.png")
                      .col-lg-5.col-md-8.col-sm-9.col-xs-12.start-lg.start-xs
                        h3 {{ $t("about.our_teams.qa.title") }}
                        .box.start-lg.start-md.center-sm.start-xs
                          .content
                            p {{ $t("about.our_teams.qa.text") }}
              #slider-counter(v-if="!isMobile")
                .counter__current-slider {{ showcase.current }}
                .counter__divider
                .counter__total-slider {{ showcase.total }}
    section#contact
      ContactSection
</template>

<script>
import Main from "../Template/Main";
import Slick from "vue-slick";
import ContactSection from "../../components/ContactSection/ContactSection";

export default {
  name: "About",
  components: {
    Main,
    ContactSection,
    Slick
  },
  data() {
    return {
      isMobile: false,
      managementTeamSlider: {
        slidesToShow: 1,
        arrows: false,
        infinite: false,
        adaptiveHeight: false,
        dots: true
      },
      teamSlider: {
        slidesToShow: 1,
        arrows: true,
        infinite: false,
        adaptiveHeight: false,
        dots: true
      },
      showcase: {
        total: 0,
        current: 0
      }
    };
  },
  methods: {
    mobileBreakpointCheck() {
      let vm = this;
      let breakPoint = 1023;
      if (window.innerWidth > breakPoint) {
        vm.isMobile = false;
      } else {
        vm.isMobile = true;
      }
    },
    // Events listeners
    handleAfterChange(event, slick, currentSlide) {
      let vm = this;
      vm.showcase.current = ("0" + (currentSlide + 1)).slice(-2);
    },
    handleInit(event, slick) {
      let vm = this;
      vm.showcase.total = ("0" + slick.slideCount).slice(-2);
      vm.showcase.current = ("0" + (slick.currentSlide + 1)).slice(-2);
    }
  },
  created() {
    let vm = this;
    $(window).resize(function() {
      vm.mobileBreakpointCheck();
    });
  },
  mounted() {
    let vm = this;
    vm.mobileBreakpointCheck();
    /* management-team */
    const sceneManagementTeam = vm.$scrollmagic.scene({
      triggerElement: "#management-team",
      reverse: true,
      duration: $("#management-team").height()
    });
    vm.$scrollmagic.addScene(
      sceneManagementTeam.setClassToggle("#about", "management-team")
    );

    /* our-teams */
    const sceneOurTeams = vm.$scrollmagic.scene({
      triggerElement: "#our-teams",
      reverse: true,
      duration: $("#our-teams").height()
    });
    vm.$scrollmagic.addScene(
      sceneOurTeams.setClassToggle("#about", "our-teams")
    );

    vm.$bus.$emit("load", false);
  }
};
</script>

<style lang="scss">
main {
  background-color: #000;
  &.management-team {
    background-color: #07061b;
  }
  &.our-teams {
    background-color: #08006f;
  }
}

#astronauts {
  width: 100%;
  position: relative;
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  img {
    user-select: none;
    position: absolute;
    &.astronauta-vr {
      animation-delay: 800ms;
      width: 100%;
      left: 0;
    }
    &.meteorito-2 {
      bottom: 0;
      right: 0;
      width: 12%;
      animation-delay: 4s;
      animation-duration: 30s;
    }
    &.meteorito-3 {
      top: 30%;
      right: 0;
      width: 20%;
      animation-delay: 1s;
    }
    &.meteorito-4 {
      bottom: 5%;
      left: 0%;
      width: 15%;
      animation-delay: 7s;
      animation-direction: reverse;
    }
  }
}

/* Section management-team */
#management-team {
  .slider-container {
    position: relative;
    .card {
      box-sizing: border-box;
      padding: 2rem;
      background-color: #0a0353;
      border-radius: 1rem;
      margin: 1rem;
      position: relative;
      h4 {
        font-weight: bold;
        font-family: "Roboto Mono";
      }
      p {
        font-size: 1rem;
      }
    }
    @media screen and (max-width: 1024px) {
      .card {
        padding: 2rem;
        border-radius: 1rem;
        margin: 1rem;
        max-height: 400px;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 5rem;
          bottom: 0;
          left: 0;
          border-radius: 1rem;
          background: linear-gradient(to bottom, transparent, #0a0353);
        }
        .box {
          overflow-y: scroll;
          max-height: calc(400px - 5.4rem);
          padding-right: 1rem;
          box-sizing: border-box;
        }
        .content {
          padding-bottom: 4rem;
        }
        h4 {
          margin-bottom: 1rem;
        }
        p {
          font-size: 0.9rem;
        }
      }
    }
  }
}

/* Section our-teams */
#our-teams {
  .mountain {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 0;
    opacity: 0.8;
  }
  .slider-container {
    position: relative;
    .card {
      box-sizing: border-box;
      padding: 2rem;
      margin: 1rem;
      position: relative;
      .our-teams__img {
        width: 80%;
        max-height: 300px;
        object-fit: contain;
        margin: 2rem 10%;
      }
      h3 {
        color: #f1a939;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 2rem;
        font-size: 1.4rem;
        margin-bottom: 1rem;
      }
      p {
        font-size: 1rem;
      }
    }
    .slick-prev,
    .slick-next {
      top: unset !important;
      bottom: -100px !important;
    }
    .slick-next {
      right: 5rem;
    }
    .slick-prev {
      right: calc(5rem + 200px);
    }
    @media screen and (min-width: 1024px) {
      .slick-dots {
        display: none !important;
      }
    }
    @media screen and (max-width: 1024px) {
      .slick-prev,
      .slick-next {
        display: none !important;
      }
      .card {
        padding: 2rem;
        margin: 1rem;
        height: 400px;
        background-color: #07061b;
        border-radius: 2rem;
        position: relative;
        @media screen and (max-width: 700px) {
          height: 500px;
          .box {
            height: 200px;
          }
        }
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 5rem;
          bottom: 0;
          left: 0;
          border-radius: 1rem;
          background: linear-gradient(to bottom, transparent, #07061b);
        }
        .our-teams__img {
          width: 80%;
          height: 150px;
          object-fit: contain;
          margin: 1rem 10%;
        }
        .box {
          overflow-y: scroll;
          max-height: 300px;
          padding-right: 1rem;
          box-sizing: border-box;
        }
        .content {
          padding-bottom: 4rem;
        }
        h4 {
          margin-bottom: 1rem;
        }
        p {
          font-size: 0.9rem;
        }
      }
    }
  }
  /* Slider Counter */
  #slider-counter {
    position: absolute;
    color: #ffffff;
    font-size: 22px;
    font-weight: bold;
    bottom: -65px;
    left: 5rem;
    .counter__current-slider {
      display: inline-block;
      vertical-align: middle;
      transition: 500ms;
    }
    .counter__divider {
      display: inline-block;
      box-sizing: border-box;
      height: 2px;
      width: 10vw;
      border: 0.5px solid rgba(255, 255, 255, 0.3);
      margin: 0 8px;
      vertical-align: middle;
    }
    .counter__total-slider {
      display: inline-block;
      vertical-align: middle;
    }
  }
}
</style>